export enum ContactTypeEnum {
    User = 1,
    Lead = 2,
    UserGroup = 3,
}

export enum UserRole {
    Administrator = 1,
    MarketingUser = 2,
    SalesManager = 3,
    SalesUser = 4,
}

export enum FilterTypeEnum {
    Unread = -1,
    None = 0,
    All = 1,
    Focused = 2,
    Unanswered = 3,
    AllChats = 4,
    AllUnanswered = 5,
    Unassigned = 6,
}

export enum SearchTypeEnum {
    RecentContact = 1,
    Leads = 2,
    Opportunity = 3,
}

export enum TimeIntervalEnum {
    None = 0,
    TODAY = 1,
    LAST_7_DAYS = 2,
    LAST_30_DAYS = 3,
    THIS_MONTH = 4,
    LAST_24_HRS = 5,
    YESTERDAY = 6,
    THIS_WEEK = 7,
    CUSTOM = 8,
}

export enum RCChatStatusEnum {
    None = 0,
    Assigned = 1,
    Closed = 2,
    WAITING_FOR_CUSTOMER = 3,
    CLOSED_BY_SYSTEM = 4,
    CLOSED_BY_AGENT = 5,
    Open = 6,
}
