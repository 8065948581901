import React, { Suspense, useEffect } from "react";
import { createPendoScriptElement } from "../Common/Utils/pendoInitService";
const FpMainComponent = React.lazy(
    () => import("../Components/FullPage/FpMain/fp.main.component")
);

/*
    Full Page Component is for desktop and full page webview.
    The main component is wrapped in Auth Component, App and CurrentUserContext
*/
export default function FullPage() {
    const pendoInitialisation = () => {
        const pendoElement = createPendoScriptElement();
        if (pendoElement) document.head.appendChild(pendoElement);
    };

    useEffect(() => {
        pendoInitialisation();
    }, []);

    return (
        <div className="cv-fullpage-entry-point">
            <Suspense fallback={null}>
                <FpMainComponent />
            </Suspense>
        </div>
    );
}
