export default class CookieHelper {
    static GetCookieByName = (name: string): string | undefined => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts && parts.length === 2) {
            const cookieNameValue = parts.pop();
            if (cookieNameValue) {
                return cookieNameValue.split(";").shift();
            }
        }
        return undefined;
    };
}
