import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

class MXEventEmitter {
    static on(event: any, fn: any) {
        return eventEmitter.on(event, fn)
    }

    static once(event: any, fn: any) {
        return eventEmitter.once(event, fn)
    }

    static emit(event: any, payload: any) {
        return eventEmitter.emit(event, payload)
    }

    static off(event: any, fn: any) {
        return eventEmitter.off(event, fn)
    }
};

export default MXEventEmitter;
