import RecentContactModel from "./recentcontacts.model";
import { SelectedContactModel } from "./selected-contact.model";

export default class RecentContactReduxState {
    RecentContacts: RecentContactModel[];
    CarterContact: RecentContactModel;
    SelectedRecentContacts: SelectedContactModel[];
    OpenedRecentContacts: RecentContactModel[];
    isLoaded: boolean;

    constructor(state?: RecentContactReduxState) {
        this.RecentContacts = state ? state.RecentContacts : [];
        this.SelectedRecentContacts = state ? state.SelectedRecentContacts : [];
        this.OpenedRecentContacts = state ? state.OpenedRecentContacts : [];
        this.isLoaded = state ? state.isLoaded : false;
        this.CarterContact = state
            ? state.CarterContact
            : ({} as RecentContactModel);
    }
}
