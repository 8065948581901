import React from 'react';

import './fullscreen-loader.component.css';

import Converse from 'Assets/Images/ConverseBrand.svg';

/*
    A full page loader for hiding UI during main data load (auth and logged in user)
*/
export default function FullScreenLoaderComponent() {
    return (
        <div className="cv-fullpage-circle-loader">
            <img src={Converse} alt="ScreenLoader" />
            <span className="cv-fullpage-slider" />
        </div>
    );
}
