import CookieHelper from "Common/Helper/CookieHelper";
import { getMobileOSType } from "Common/Utils/CheckDevice";

export const getMobileAuthCookie = () =>
    JSON.parse(CookieHelper.GetCookieByName("MOBILEAUTH") || "{}");

export const getMobileAuthCookieFromCb = async () => {
    const osType = getMobileOSType();
    let cookie = {};

    if (osType === "ios" && getMobileAuthCookie().length <= 0) {
        const cookiePromise = (
            window as any
        ).webkit?.messageHandlers?.fetchConverseMobileCookie?.postMessage(
            "fetchConverseMobileCookie"
        );
        cookie = await cookiePromise
            .then((res: any) => (res ? JSON.parse(res) : {}))
            .catch((e: any) => {});
    }
    return cookie;
};

export const getPlatformNamefromCookie = (mobileAuthCookie: any) =>
    Object.keys(mobileAuthCookie).length &&
    mobileAuthCookie?.Platform?.toLowerCase();
