import React, { createContext, useReducer } from "react";

import ContactModel from "Common/Models/contact.model";
import { LicensedModules, AppMode } from "Common/Enums/metadata.enum";
import { SessionModel } from "Common/Models/SessionObject/sessionobject.model";

const CurrentUserContext = createContext<CurrentUserContextModel>(
    {} as CurrentUserContextModel
);

function deleteFromObject(
    keyPart: string,
    obj: { [key: string]: SessionModel }
) {
    for (var k in obj) {
        // Loop through the object
        if (~k.indexOf(keyPart)) {
            // If the current key contains the string we're looking for
            delete obj[k]; // Delete obj[key];
        }
    }
    return obj;
}
const { Provider } = CurrentUserContext;
const reducer = (
    state: CurrentUserState,
    action: {
        type: string;
        payload: ContactModel;
        features: LicensedModules[];
        mode: AppMode;
        role: string;
        sessionMap?: { [key: string]: SessionModel };
        sessionKey?: string;
        adminView?: boolean;
        assignmentAllowed?: boolean;
    }
) => {
    const newState = { ...state };
    switch (action.type) {
        case UserContextDispatchTypes.SET_LOGGEDIN_USER:
            newState.userData = action.payload;
            newState.features = action.features;
            newState.mode = action.mode;
            newState.role = action.role;
            return newState;

        case UserContextDispatchTypes.SET_SESSION_OBJECT:
            newState.userData = state.userData;
            newState.features = state.features;
            newState.mode = state.mode;
            newState.role = state.role;
            newState.sessionMap = { ...state.sessionMap, ...action.sessionMap };
            return newState;

        case UserContextDispatchTypes.DELETE_SESSION_OBJECT:
            newState.userData = state.userData;
            newState.features = state.features;
            newState.mode = state.mode;
            newState.role = state.role;
            newState.sessionMap = deleteFromObject(
                action.sessionKey ? action?.sessionKey : "",
                state.sessionMap ? state.sessionMap : {}
            );
            return newState;

        case UserContextDispatchTypes.SET_FILTER_VIEW:
            newState.adminView = action.adminView;
            newState.assignmentAllowed = action.assignmentAllowed;
            return newState;

        default:
            throw new Error();
    }
};

const CurrentUserContextProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, {
        userData: {},
    } as CurrentUserState);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

interface CurrentUserContextModel {
    state: CurrentUserState;
    dispatch: React.Dispatch<{
        type: string;
        payload: ContactModel;
        features: LicensedModules[];
        mode: AppMode;
        role: string;
        sessionMap?: { [key: string]: SessionModel };
        adminView?: boolean;
        assignmentAllowed?: boolean;
    }>;
}
export interface CurrentUserState {
    userData: ContactModel;
    features: LicensedModules[];
    mode: AppMode;
    role: string;
    sessionMap?: { [key: string]: SessionModel };
    adminView?: boolean;
    assignmentAllowed?: boolean;
}
class UserContextDispatchTypes {
    static SET_LOGGEDIN_USER = "SET_LOGGEDIN_USER";
    static SET_SESSION_OBJECT = "SET_SESSION_OBJECT";
    static DELETE_SESSION_OBJECT = "DELETE_SESSION_OBJECT";
    static SET_FILTER_VIEW = "SET_FILTER_VIEW";
}

class UserContextDispatchHelper {
    static getDispatchToSetUser = (
        loggedInUser: ContactModel,
        features: LicensedModules[],
        mode: AppMode,
        role: string
    ) => ({
        type: "SET_LOGGEDIN_USER",
        payload: loggedInUser,
        features,
        mode,
        role,
    });

    static getDispatchToSetFilterView = (
        adminView: boolean,
        assignmentAllowed: boolean
    ) => ({
        type: "SET_FILTER_VIEW",
        payload: {} as ContactModel,
        features: [],
        mode: AppMode.Widget,
        role: "",
        adminView,
        assignmentAllowed,
    });

    static getDispatchToSetSession = (sessionMap?: {
        [key: string]: SessionModel;
    }) => ({
        type: UserContextDispatchTypes.SET_SESSION_OBJECT,
        payload: {} as ContactModel,
        features: [],
        mode: AppMode.Widget,
        role: "",
        sessionMap: sessionMap ? sessionMap : {},
    });
    static dispatchDeleteSessionObject = (sessionKey: string) => ({
        type: UserContextDispatchTypes.DELETE_SESSION_OBJECT,
        payload: {} as ContactModel,
        features: [],
        mode: AppMode.Widget,
        role: "",
        sessionMap: {},
        sessionKey: sessionKey,
    });
}

/*
   CurrentUserContextProvider: Component, when wrapper around child components, will give access to CurrentUserContext to those child components
   CurrentUserContext: Has details for currently logged in user
   UserContextDispatchHelper: Has dispatch payload as helpers
*/
export {
    CurrentUserContextProvider,
    CurrentUserContext,
    UserContextDispatchHelper,
};
