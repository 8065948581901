export enum LicensedModules {
    UserToUser = 1,
    UserToLead = 2,
    MessageSearch = 3,
    GoToFullScreen = 4,
    EmoticonSupport = 5,
    SystemToUser = 6,
}

export enum AppMode {
    Widget = 1,
    FullScreen = 2,
    MarvinFullScreen = 3,
    MarvinWidget = 4,
    MarvinMobile = 5,
    LsqMobile = 6,
}

export enum OsType {
    Unknown = 0,
    Windows = 1,
    MacOS = 2,
    Linux = 3,
    Android = 4,
    IOS = 5,
}

export enum UserStatus {
    AVAILABLE = 1,
    UNAVAILABLE = 2,
}
