import { addMinutes } from "date-fns";

export default class MXCache {
    static UserDetailKey = "LSQ_CONVERSE_UserFields";
    static CopyUserDetailKey = "Copy_UserFields";
    static LeadPhoneFeildsKey = "LSQ_CONVERSE_LeadPhoneFields";
    static LeadMergeFeildsKey = "LSQ_CONVERSE_LeadMergeFields";
    static ConnectorConfigKey = "LSQ_CONVERSE_ConnectorConfig";
    static LicensedFeaturesKey = "LSQ_CONVERSE_LFeat";
    static HistorySizeKey = "LSQ_CONVERSE_CV_H_S";
    static AuthKey = "LSQ_CONVERSE_AUTH";
    static CopyAuthKey = "Copy_AUTH";
    static OpenedContactsKey = "LSQ_CONVERSE_OPENED_CONTACTS";
    static RecentContactsOpenedKey = "LSQ_CONVERSE_RECENT_CONTACTS_OPENED";
    static RecentContacts = "LSQ_CONVERSE_RECENT_CONTACTS";
    static RecentSearchesKey = "LSQ_CONVERSE_RECENT_SEARCHES";
    static SystemUserKey = "LSQ_CONVERSE_System_User";
    static ConnectorComposerConfigKey = "LSQ_CONVERSE_ConnectorComposerConfig";
    static SocketInfraVersionKey = "LSQ_CONVERSE_SocketInfraVersion";
    static FilterDataKey = "LSQ_CONVERSE_FilterData";
    static PreviousSelectedFilters = "LSQ_CONVERSE_PreviousSelectedFilters";
    static RecentContactModeKey = "LSQ_CONVERSE_RecentContactMode";
    static IsAdminViewkey = "LSQ_CONVERSE_IsAdminView";
    static IsServiceCloudEnabled = "LSQ_CONVERSE_IsServiceCloudEnabled";
    static AppModeKey = "LSQ_CONVERSE_APP_MODE";
    static AvailabilitySettings = "LSQ_CONVERSE_Availability_Settings";
    static UserPreference = "LSQ_CONVERSE_UserPreference";
    static UnreadChatCount = "LSQ_CONVERSE_UnreadChatCount";
    static Carter = "LSQ_CONVERSE_Carter";
    static IsAppRefreshed = "LSQ_CONVERSE_IsAppRefreshed";
    static Localization = "LSQ_CONVERSE_Localization";
    static UserRole = "LSQ_CONVERSE_UserRole";
    static opportunityFilterOptions = "LSQ_CONVERSE_OPPORTUNITY_FILTER_OPTIONS";
    static fs_opportunityFilterOptions =
        "LSQ_CONVERSE_FS_OPPORTUNITY_FILTER_OPTIONS";
    static fs_eventDetails = "LSQ_CONVERSE_FS_EVENT_DETAILS";
    static TokenExpiryTime = "LSQ_CONVERSE_TOKEN_EXPIRE_TIME";

    static SetCacheItem(key: string, value: any, expiryMinutes = 120): void {
        const expireIn = addMinutes(new Date(), expiryMinutes);
        const cacheItem = { value, expireIn };
        localStorage.setItem(key, JSON.stringify(cacheItem));
    }

    static GetCacheItem(key: string): any | null {
        const storageVal = localStorage.getItem(key);
        if (storageVal) {
            try {
                const parsedVal = JSON.parse(storageVal);
                if (parsedVal && parsedVal.value) {
                    if (
                        parsedVal.expireIn &&
                        new Date(parsedVal.expireIn) > new Date()
                    ) {
                        return parsedVal.value;
                    }
                }
            } catch (error) {
                return null;
            }
        }
        return null;
    }

    static Clear = (): void => {
        const keysToClear = [
            MXCache.UserDetailKey,
            MXCache.LeadPhoneFeildsKey,
            MXCache.LeadMergeFeildsKey,
            MXCache.ConnectorConfigKey,
            MXCache.LicensedFeaturesKey,
            MXCache.HistorySizeKey,
            MXCache.AuthKey,
            MXCache.OpenedContactsKey,
            MXCache.RecentContactsOpenedKey,
            MXCache.RecentSearchesKey,
            MXCache.SystemUserKey,
            MXCache.ConnectorComposerConfigKey,
            MXCache.SocketInfraVersionKey,
            MXCache.FilterDataKey,
            MXCache.RecentContactModeKey,
            MXCache.IsAdminViewkey,
            MXCache.IsServiceCloudEnabled,
            MXCache.AppModeKey,
            MXCache.AvailabilitySettings,
            MXCache.UserPreference,
            MXCache.UnreadChatCount,
            MXCache.Carter,
            MXCache.Localization,
            MXCache.UserRole,
            MXCache.opportunityFilterOptions,
            MXCache.fs_opportunityFilterOptions,
            MXCache.fs_eventDetails,
            MXCache.TokenExpiryTime,
        ];
        for (let index = 0; index < keysToClear.length; index++) {
            const key = keysToClear[index];
            localStorage.removeItem(key);
        }
    };

    static RemoveOneItem(key: string): void {
        localStorage.removeItem(key);
    }
}
