import MXCache from "Common/CacheManager/MXCache";
import { ContactBoxMode } from "Common/Models/selected-contact.model";

export default class AppContextState {
    MessageSubscribers: { [key: string]: Function };

    SyncStateSubscribers: { [key: string]: Function };

    LocalizationObject: { [key: string]: string };

    LoadedItems: string[];

    HistorySize: number;

    IsLeadOpenedThroughRC: boolean;

    IsInitialAppLoading: boolean;

    //marvin context
    AuthenticationDetails: { [key: string]: string };
    UserDetails: { [key: string]: string };
    TenantDetails: { [key: string]: string };
    Language: { [key: string]: string };
    ThemeConfig: { [key: string]: string };
    ModalState: { modalOpened: boolean };
    isMarvinLoadedInPlatform = false;
    RecentContactMode =
        MXCache.GetCacheItem(MXCache.RecentContactModeKey) ??
        ContactBoxMode.Minimized;
    constructor() {
        this.MessageSubscribers = {};
        this.SyncStateSubscribers = {};
        this.LocalizationObject = {};
        this.LoadedItems = [];
        this.HistorySize = 0;

        this.AuthenticationDetails = {};
        this.UserDetails = {};
        this.TenantDetails = {};
        this.Language = { language: "", direction: "" };
        this.ThemeConfig = {};
        this.ModalState = { modalOpened: false };
        this.isMarvinLoadedInPlatform = false;
        this.RecentContactMode =
            MXCache.GetCacheItem(MXCache.RecentContactModeKey) ??
            ContactBoxMode.Minimized;
        this.IsLeadOpenedThroughRC = false;
        this.IsInitialAppLoading = false;
    }
}
