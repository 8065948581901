import MXCache from "Common/CacheManager/MXCache";
import MXLogger from "Common/MXLogger";
import { AppMode } from "Common/Enums/metadata.enum";
import MessageHistoryItem from "Common/Models/message-history-item.model";
import RecentContactReduxState from "Common/Models/recent-contacts.redux-state";
import RecentContactModel from "Common/Models/recentcontacts.model";
import {
    ContactBoxMode,
    SelectedContactModel,
} from "Common/Models/selected-contact.model";
import { orderBy } from "lodash";

const getSelectedContactsFromSessionStorage = (): SelectedContactModel[] => {
    try {
        const contacts = MXCache.GetCacheItem(
            MXCache.OpenedContactsKey
        ) as SelectedContactModel[];
        if (contacts && contacts.length) {
            return contacts;
        }
    } catch (error) {
        MXLogger.error(error);
    }
    return [];
    // try {
    //     const contactsFromStorage = MXCache.GetCacheItem(
    //         MXCache.RecentContactsOpenedKey
    //     ) as RecentContactModel[];
    //     let contacts = contactsFromStorage?.map(
    //         (item) => new RecentContactModel(item).toSelectedContact(null) // converting to Contact Model
    //     );
    //     if (contacts?.length) {
    //         return contacts;
    //     }
    // } catch (error) {
    //     MXLogger.error(error);
    // }
    // return [];
};

const getOpenedRecentContactsFromSessionStorage = (): RecentContactModel[] => {
    try {
        const contactsFromStorage = MXCache.GetCacheItem(
            MXCache.RecentContactsOpenedKey
        ) as RecentContactModel[];
        let contacts = contactsFromStorage?.map(
            (item) => new RecentContactModel(item)
        );
        if (contacts?.length) {
            return contacts;
        }
    } catch (error) {
        MXLogger.error(error);
    }
    return [];
};

const getDefaultReduxState = () => {
    const state = new RecentContactReduxState();
    state.SelectedRecentContacts = getSelectedContactsFromSessionStorage();
    state.OpenedRecentContacts = getOpenedRecentContactsFromSessionStorage();
    return state;
};

const RecentContactsReducer = (
    state: RecentContactReduxState = getDefaultReduxState(),
    action: {
        type: string;
        contacts: RecentContactModel[];
        contact: RecentContactModel;
        App: AppMode;
        contactBoxMode: ContactBoxMode;
        message: MessageHistoryItem;
        reorder: boolean;
        isSystemUserOnly?: boolean;
    }
) => {
    let newState: RecentContactModel[];
    switch (action.type) {
        case "SET_RECENT_CONTACTS":
            return setRecentContactAndUpdateOpenedContact(
                state,
                action.contacts,
                action.isSystemUserOnly
            );
        case "SET_LOAD_STATE_FALSE":
            return {
                ...state,
                RecentContacts: [],
                isLoaded: false,
                SearchMetadata: null,
            };
        case "SELECT_CONTACT":
            return selectContact(state, action.contact, action.App, undefined);
        case "SELECT_CONTACT_MINIMIZED":
            return selectContact(
                state,
                action.contact,
                action.App,
                undefined,
                ContactBoxMode.Minimized,
                action.isSystemUserOnly
            );
        case "SET_MODE_CONTACT_BOX":
            return setModeContactBox(
                state,
                action.contact,
                action.contactBoxMode
            );
        case "REMOVE_CONTACT_SELECTION":
            return removeContactFromSelection(
                state,
                action.contact,
                action.App
            );
        case "SELECT_CONTACT_WITH_MESSAGE":
            return selectContact(
                state,
                action.contact,
                action.App,
                action.message
            );
        case "CHANGE_CONTACT_DATA":
            return changeContact(state, action.contact, action.reorder);
        case "ADD_CONTACT":
            return addContact(state, action.contact);
        case "SET_UNREAD_ONLY":
            return setUnreadOnly(state, action.contact);
        case "SET_STATUS":
            return setStatus(state, action.contact);
        case "SET_READ_POINTER_ONLY":
            return setReadPointer(state, action.contact);
        case "SET_RECENT_CONTACTS_WITH_SELECTED_CONTACT":
            return {
                ...state,
                RecentContacts: action.contacts ?? [],
                SelectedRecentContacts: action.contact
                    ? [action.contact.toSelectedContact(null)]
                    : [],
                isLoaded: true,
            };
        case "SET_CARTER_CONTACTS":
            return {
                ...state,
                CarterContact: action.contact,
            };
        case "SELECT_SEARCHED_CONTACT":
            return selectSearchedContact(
                state,
                action.contact,
                action.App,
                undefined,
                ContactBoxMode.Maximized
            );
        case "REMOVE_SEARCHED_CONTACT":
            return removeSearchedContact(state, action.contact);
        case "EMPTY_SELECTED_CONTACTS":
            return emptySelectedContacts(state);
            return {
                ...state,
                SelectedRecentContacts: [],
                OpenedRecentContacts: [],
            };
        case "UPDATE_OPENED_RECENT_CONTACT":
            return updateOpenedRecentContact(state, action.contact);
        default:
            return state;
    }
};

let emptySelectedContacts = (state: RecentContactReduxState) => {
    saveContactsToCache([]);
    saveOpenedRecentContacts([]);
    return {
        ...state,
        SelectedRecentContacts: [],
        OpenedRecentContacts: [],
    };
};

let updateOpenedRecentContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel
) => {
    let OpenedRecentContacts = state.OpenedRecentContacts?.map((orc) => {
        if (
            orc.contact.opportunityInstanceId ===
            contact.contact.opportunityInstanceId
        ) {
            let temp = orc;
            temp.unreadMessages = orc.unreadMessages + 1;

            return temp;
        }
        return orc;
    });
    // !isSystemUserOnly &&
    saveOpenedRecentContacts(OpenedRecentContacts);
    return {
        ...state,
        OpenedRecentContacts,
    };
};

let setRecentContactAndUpdateOpenedContact = (
    state: RecentContactReduxState,
    contacts: RecentContactModel[],
    isSystemUserOnly?: boolean
) => {
    const newContacts = [...contacts];
    let OpenedRecentContacts = state.OpenedRecentContacts?.map((orc) => {
        let contactItem = newContacts.find(
            (contact) =>
                contact.contact.opportunityInstanceId ===
                orc.contact.opportunityInstanceId
        );
        if (contactItem) {
            return contactItem;
        }
        return orc;
    });
    !isSystemUserOnly && saveOpenedRecentContacts(OpenedRecentContacts);
    const RecentContacts = orderContacts(newContacts);
    MXCache.SetCacheItem(MXCache.RecentContacts, RecentContacts);
    return {
        ...state,
        RecentContacts,
        OpenedRecentContacts,
        isLoaded: true,
        SearchMetadata: null,
    };
};

let selectContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel,
    appMode: AppMode,
    message: MessageHistoryItem | undefined,
    mode: ContactBoxMode = ContactBoxMode.Maximized,
    isSystemUserOnly?: boolean
): RecentContactReduxState => {
    const sc = state?.SelectedRecentContacts
        ? state.SelectedRecentContacts
        : [];
    const oc = state?.OpenedRecentContacts ? state.OpenedRecentContacts : [];
    let recentContacts = state.RecentContacts ?? [];
    const index = recentContacts.findIndex((r) =>
        r.isSameRecentContact(contact)
    );
    let selectedContacts = [] as any;
    let openedRecentContacts = [] as RecentContactModel[];
    let SearchMetadata = null as any;
    if (message) {
        SearchMetadata = {
            IsMessageSearch: true,
            MessageSearched: message,
        };
    }
    if (index > -1) {
        recentContacts[index].unreadMessages =
            mode === ContactBoxMode.Maximized
                ? 0
                : recentContacts[index].unreadMessages;
    } else {
        recentContacts = [contact, ...recentContacts];
        const systemUserIndex = recentContacts.findIndex(
            (rc) => rc.contact.isContactSystemUser === true
        );
        if (systemUserIndex > -1) {
            const systemUser = recentContacts.splice(systemUserIndex, 1);
            if (systemUser && systemUser.length >= 1)
                recentContacts.unshift(systemUser[0]);
        }
    }
    if (
        appMode === AppMode.FullScreen ||
        appMode === AppMode.MarvinFullScreen
    ) {
        selectedContacts = [contact.toSelectedContact(SearchMetadata, mode)];
        openedRecentContacts = [contact];
    } else {
        const selectedContactIndex = sc.findIndex(
            (r) =>
                r.opportunityInstanceId ===
                contact.contact.opportunityInstanceId
        );
        if (selectedContactIndex === -1) {
            if (appMode === AppMode.MarvinWidget) {
                if (sc.length > 0) {
                    sc.pop();
                }
            } else {
                if (sc.length > 1) {
                    sc.pop();
                    oc.pop();
                }
            }

            selectedContacts = [
                contact.toSelectedContact(SearchMetadata, mode),
                ...sc,
            ];
            if (appMode === AppMode.MarvinWidget) {
                openedRecentContacts = [contact];
            } else {
                openedRecentContacts = [contact, ...oc];
            }
        } else {
            if (mode !== ContactBoxMode.Minimized) {
                sc[selectedContactIndex].Mode = mode;
            }
            sc[selectedContactIndex].SearchMetadata = SearchMetadata;
            if (appMode === AppMode.MarvinWidget) {
                selectedContacts = [sc[selectedContactIndex]];
            } else {
                selectedContacts = [...sc];
            }
        }
    }

    !isSystemUserOnly && saveContactsToCache(selectedContacts);
    !isSystemUserOnly && saveOpenedRecentContacts(openedRecentContacts);
    return {
        ...state,
        RecentContacts: recentContacts,
        SelectedRecentContacts: selectedContacts,
        OpenedRecentContacts: openedRecentContacts,
    };
};

let selectSearchedContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel,
    appMode: AppMode,
    message: MessageHistoryItem | undefined,
    mode: ContactBoxMode = ContactBoxMode.Maximized
): RecentContactReduxState => {
    const sc =
        state && state.SelectedRecentContacts
            ? state.SelectedRecentContacts
            : [];
    let orc = state?.OpenedRecentContacts ? state.OpenedRecentContacts : [];
    let recentContacts = state.RecentContacts ?? [];
    const index = orc.findIndex((r) => r.isSameRecentContact(contact));
    let selectedContacts = [] as any;
    let SearchMetadata = null as any;
    if (message) {
        SearchMetadata = {
            IsMessageSearch: true,
            MessageSearched: message,
        };
    }
    if (index > -1 && mode === ContactBoxMode.Maximized) {
        // UnreadMessage = 0 for searchedRecentContacts
        orc[index].unreadMessages = 0;
    } else {
        if (orc.length > 1) {
            orc.pop();
        }
        if (appMode === AppMode.MarvinWidget) {
            orc = [contact];
        } else {
            orc = [contact, ...orc];
        }
    }

    // make UnreadMessage = 0 for main RecentContact also, if present
    const indexInRecentContactList = recentContacts.findIndex((r) =>
        r.isSameRecentContact(contact)
    );
    if (indexInRecentContactList > -1) {
        recentContacts[indexInRecentContactList].unreadMessages = 0;
    }

    if (
        appMode === AppMode.FullScreen ||
        appMode === AppMode.MarvinFullScreen
    ) {
        selectedContacts = [contact.toSelectedContact(SearchMetadata, mode)];
    } else {
        const selectedContactIndex = sc.findIndex(
            (r) =>
                r.opportunityInstanceId ===
                contact.contact.opportunityInstanceId
        );
        if (selectedContactIndex === -1) {
            if (appMode === AppMode.MarvinWidget) {
                selectedContacts = [
                    contact.toSelectedContact(SearchMetadata, mode),
                ];
            } else {
                if (sc.length > 1) {
                    sc.pop();
                    orc.pop();
                }
                selectedContacts = [
                    contact.toSelectedContact(SearchMetadata, mode),
                    ...sc,
                ];
            }
        } else {
            if (mode !== ContactBoxMode.Minimized) {
                sc[selectedContactIndex].Mode = mode;
            }
            sc[selectedContactIndex].SearchMetadata = SearchMetadata;
            if (appMode === AppMode.MarvinWidget) {
                selectedContacts = [sc[selectedContactIndex]];
            } else {
                selectedContacts = [...sc];
            }
        }
    }

    saveOpenedRecentContacts(orc);
    saveContactsToCache(selectedContacts);

    // if RecentContact is already present in the list
    if (indexInRecentContactList > -1) {
        return {
            ...state,
            RecentContacts: recentContacts,
            SelectedRecentContacts: selectedContacts,
            OpenedRecentContacts: orc,
        };
    }

    return {
        ...state,
        RecentContacts: recentContacts,
        SelectedRecentContacts: selectedContacts,
        OpenedRecentContacts: orc,
    };
};

// const isSearchedContact = (
//     state: RecentContactReduxState,
//     contact: RecentContactModel
// ) => {
//     const searchedRecentContacts = state.SearchedRecentContacts ?? [];
//     const index = searchedRecentContacts.findIndex(
//         (item) =>
//             item.contact.opportunityInstanceId ===
//             contact.contact.opportunityInstanceId
//     );
//     if (index > -1) {
//         return true;
//     }
//     return false;
// };

let setModeContactBox = (
    state: RecentContactReduxState,
    contact: RecentContactModel,
    mode: ContactBoxMode
): RecentContactReduxState => {
    const sc =
        state && state.SelectedRecentContacts
            ? state.SelectedRecentContacts
            : [];
    const index = sc.findIndex(
        (r) => r.opportunityInstanceId === contact.contact.opportunityInstanceId
    );

    if (index > -1) {
        sc[index].Mode = mode;
        saveContactsToCache(sc);
        return {
            ...state,
            SelectedRecentContacts: sc,
        };
    }
    return state;
};

let removeContactFromSelection = (
    state: RecentContactReduxState,
    contact: RecentContactModel,
    appMode: AppMode
): RecentContactReduxState => {
    const sc =
        state && state.SelectedRecentContacts
            ? state.SelectedRecentContacts
            : [];
    const oc = state?.OpenedRecentContacts ? state.OpenedRecentContacts : [];
    const index = sc.findIndex(
        (r) => r.opportunityInstanceId === contact.contact.opportunityInstanceId
    );
    if (index > -1) {
        sc.splice(index, 1);
        saveContactsToCache(sc);
    }
    const openRCIndex = oc.findIndex(
        (r) =>
            r.contact.opportunityInstanceId ===
            contact.contact.opportunityInstanceId
    );
    if (openRCIndex > -1) {
        oc.splice(openRCIndex, 1);
        saveOpenedRecentContacts(oc);
    }
    return {
        ...state,
        SelectedRecentContacts: sc,
        OpenedRecentContacts: oc,
    };
};
let changeContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel,
    reorder: boolean
): RecentContactReduxState => {
    let OpenedRecentContacts = state.OpenedRecentContacts?.map((orc) => {
        if (
            orc.contact.opportunityInstanceId ===
            contact.contact.opportunityInstanceId
        ) {
            return contact;
        }
        return orc;
    });
    // !isSystemUserOnly &&
    saveOpenedRecentContacts(OpenedRecentContacts);
    if (state.RecentContacts) {
        let index = state.RecentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (index !== -1) {
            const rcontacts = [...state.RecentContacts];
            rcontacts[index] = contact;
            return {
                ...state,
                OpenedRecentContacts,
                RecentContacts: reorder ? orderContacts(rcontacts) : rcontacts,
            };
        }
    }

    return {
        ...state,
        OpenedRecentContacts,
    };
};

let setUnreadOnly = (
    state: RecentContactReduxState,
    contact: RecentContactModel
): RecentContactReduxState => {
    if (state.RecentContacts) {
        const index = state.RecentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (index !== -1) {
            const rcontacts = [...state.RecentContacts];
            rcontacts[index].unreadMessages = contact.unreadMessages;
            return {
                ...state,
                RecentContacts: rcontacts,
            };
        }
    }
    let OpenedRecentContacts = state.OpenedRecentContacts?.map((orc) => {
        if (
            orc.contact.opportunityInstanceId ===
            contact.contact.opportunityInstanceId
        ) {
            let temp = orc;
            temp.unreadMessages = contact.unreadMessages;

            return temp;
        }
        return orc;
    });
    //!isSystemUserOnly &&
    saveOpenedRecentContacts(OpenedRecentContacts);
    return state;
};

let setStatus = (
    state: RecentContactReduxState,
    contact: RecentContactModel
): RecentContactReduxState => {
    if (state.RecentContacts) {
        const index = state.RecentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (index !== -1) {
            const rcontacts = [...state.RecentContacts];
            rcontacts[index].contact.status = 2;
            return {
                ...state,
                RecentContacts: rcontacts,
            };
        }
    }
    return state;
};

let setReadPointer = (
    state: RecentContactReduxState,
    contact: RecentContactModel
): RecentContactReduxState => {
    if (state.RecentContacts) {
        const index = state.RecentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (index !== -1) {
            const rcontacts = [...state.RecentContacts];
            rcontacts[index].readPointer = contact.readPointer;
            return {
                ...state,
                RecentContacts: rcontacts,
            };
        }
        //check Opened Contacts
        const Openedindex = state.OpenedRecentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (Openedindex !== -1) {
            const openedcontacts = [...state.OpenedRecentContacts];
            openedcontacts[Openedindex].readPointer = contact.readPointer;
            // !isSystemUserOnly &&
            saveOpenedRecentContacts(openedcontacts);
            return {
                ...state,
                OpenedRecentContacts: openedcontacts,
            };
        }
    }
    return state;
};

let addContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel
): RecentContactReduxState => {
    const recentContacts = state.RecentContacts ?? [];
    if (recentContacts) {
        const index = recentContacts.findIndex((r) =>
            r.isSameRecentContact(contact)
        );
        if (index !== -1) {
            const rcontacts = [...recentContacts];
            rcontacts[index] = contact;
            return {
                ...state,
                RecentContacts: rcontacts,
            };
        }

        const rcontacts = [contact, ...recentContacts];
        return {
            ...state,
            RecentContacts: rcontacts,
        };
    }

    return {
        ...state,
        RecentContacts: [contact],
    };
};

let removeSearchedContact = (
    state: RecentContactReduxState,
    contact: RecentContactModel
): RecentContactReduxState => {
    const openedRecentContacts = state.OpenedRecentContacts;
    if (openedRecentContacts) {
        const sRecentContacts = openedRecentContacts.filter(
            (r) => !r.isSameRecentContact(contact)
        );

        return {
            ...state,
            OpenedRecentContacts: sRecentContacts,
        };
    }

    return state;
};

let saveContactsToCache = (sc: SelectedContactModel[]) => {
    const scc = JSON.parse(JSON.stringify(sc)) as SelectedContactModel[];
    MXCache.SetCacheItem(
        MXCache.OpenedContactsKey,
        scc.map((i) => ({
            ...i,
            SearchMetadata: null,
        })),
        120
    );
};

let saveOpenedRecentContacts = (oc: RecentContactModel[]) => {
    const occ = JSON.parse(JSON.stringify(oc)) as RecentContactModel[];
    MXCache.SetCacheItem(
        MXCache.RecentContactsOpenedKey,
        occ.map((i) => ({
            ...i,
        })),
        120
    );
};

let orderContacts = (contacts: RecentContactModel[]) => {
    const systemUserIndex = contacts.findIndex(
        (n) => n.contact.isContactSystemUser === true
    );
    let finalContacts = contacts;
    if (systemUserIndex > -1) {
        const systemUserItem = contacts[systemUserIndex];
        contacts.splice(systemUserIndex, 1);
        const orderedContacts = orderBy(
            contacts,
            (c) => new Date(c?.message?.recentContactTimeStamp).getTime(),
            ["desc"]
        );
        finalContacts = [systemUserItem, ...orderedContacts];
    } else {
        const orderedContacts = orderBy(
            contacts,
            (c) => new Date(c?.message?.recentContactTimeStamp).getTime(),
            ["desc"]
        );
        finalContacts = orderedContacts;
    }
    return finalContacts;
};

export default RecentContactsReducer;
