import {
    DashboardChatCounts,
    UnansweredChatCount,
    TotalMessageCounts,
} from "Common/Models/Dashboard/dashboard.model";
import API from "../API";
import { TimeIntervalEnum } from "Common/Enums/contact.enum";

export default class DashboardServices extends API {
    static baseUrl = process.env.REACT_APP_INSIGHTS_BASE_URL || "";
    constructor() {
        super(DashboardServices.baseUrl);
    }

    async getChatCounts({
        timeInterval,
        currentTime,
        waitTime,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
    }): Promise<DashboardChatCounts> {
        return this.Responsify<any>(
            await this.instance.get(
                `/consolidatedCounts?timeInterval=${timeInterval}&currentTime=${currentTime}&waitTime=${waitTime}`
            )
        );
    }
    async getUnansweredChatCounts({
        timeInterval,
        currentTime,
        waitTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<UnansweredChatCount> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/unansweredChatCount`, {
                timeInterval,
                currentTimestamp: currentTime,
                waitTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }

    async getUnassignedChatCount({
        timeInterval,
        currentTime,
        waitTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        waitTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<UnansweredChatCount> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/unassignedChatCount`, {
                timeInterval,
                currentTimestamp: currentTime,
                waitTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }
    async getTotalMessageCountAnalytics({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/messageCounts`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }
    async getTotalChatCountAnalytics({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/chatCounts`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }

    async postNewRelicLog(logObject: any): Promise<any> {
        return this.Responsify<any>(
            await this.instance.post(`/instrument/apm`, logObject)
        );
    }

    async getAgentPerformanceReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/agent-performance`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }

    async getChatStatusReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/chat-status-report`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }

    async getAverageSlaReport({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/avg-sla-report`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }

    async getChatInitiatedByCustomer({
        timeInterval,
        currentTime,
        startTimestamp,
        endTimestamp,
    }: {
        timeInterval: TimeIntervalEnum;
        currentTime: number;
        startTimestamp: number;
        endTimestamp: number;
    }): Promise<TotalMessageCounts> {
        return this.Responsify<any>(
            await this.instance.post(`/dashboard/chatsInitiated/lead`, {
                timeInterval,
                currentTimestamp: currentTime,
                startTimestamp,
                endTimestamp,
            })
        );
    }
}
