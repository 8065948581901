import log from "loglevel";
import buildConsoleLogger from "./ConsoleLogger";
import buildLogServiceLogger from "./LogServiceLogger";

export interface ILogger {
    info: (message: string, ...params: any) => void;
    warn: (message: string, ...params: any) => void;
    error: Function;
    debug: (message: string, ...params: any) => void;
    trace: (message: string, ...params: any) => void;
}

function Logger(): ILogger {
    let MXLogger: ILogger;

    const getLogLevel = (logLevel: string | undefined) => {
        switch (logLevel) {
            case "0":
                return log.levels.TRACE;
            case "1":
                return log.levels.DEBUG;
            case "2":
                return log.levels.INFO;
            case "3":
                return log.levels.WARN;
            case "4":
                return log.levels.ERROR;
            default:
                return log.levels.SILENT;
        }
    };

    let loglevel = getLogLevel(process.env.REACT_APP_LOG_LEVEL);
    log.setLevel(loglevel);

    if (process.env.REACT_APP_LOG_MODE === "console") {
        MXLogger = buildConsoleLogger();
    } else if (process.env.REACT_APP_LOG_MODE === "log-service") {
        MXLogger = buildLogServiceLogger();
    } else {
        MXLogger = {
            info: () => {},
            warn: () => {},
            error: () => {},
            debug: () => {},
            trace: () => {},
        };
    }

    return MXLogger;
}

const MXLogger = Logger();

export default MXLogger;
